import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import StaticImg from "../../../images/static-banner-1.jpg"
import StaticImg2 from "../../../images/static-banner-2.jpg"
import StaticImg3 from "../../../images/static-banner-3.jpg"
import ReactMarkdown from "react-markdown"
import GoogleReviews from "../../../images/google-reviews.png"
import "./StaticBanner.scss"
import { Reviews_Page_Url } from "@Components/common/site/constants"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import GetURL from "@Components/common/site/get-url"
import axios from "axios"
import wordsToNumbers from "words-to-numbers"
import SearchMultipleArea from "../../SearchResults/SecondaryHeader/search-multiarea"
import AreaListBox from "../../SearchResults/SecondaryHeader/AreaList"
import _, {
  isEmpty,
  filter,
  find,
  isArray,
  isString,
  get,
  indexOf,
} from "lodash"
import { navigate } from "@reach/router"
import CareerForm from "@Components/forms/careers"
import Modal from "react-bootstrap/Modal"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
import {token} from "@Components/forms/api/Api"

const StaticBanner = props => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setShow(true)
  }
  const [isOpen, setIsOpen] = useState(false)
  const [testimonials, setTestimonials] = useState([])
  const [isClient, setClient] = useState(false)
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "multipart/form-data"
        }
      }) // could be from env files
      setTestimonials(data)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }
  useEffect(() => {
    let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
    getitems(url)
    setClient(true)
  }, [])
  var testimonial_count = testimonials ? testimonials.length : 0
  var rating_count = 0
  testimonials &&
    testimonials.length > 0 &&
    testimonials.map((item, ind) => {
      rating_count += parseInt(wordsToNumbers(item.starRating))
    })
  var rating_avg = rating_count / testimonial_count

  const [type, setType] = useState("1")
  useEffect(() => {
    setType(
      props.Alias === "commercial"
        ? "3"
        : props.Alias === "auctions"
        ? "4"
        : "1"
    )
  }, [props.Alias])

  const toggling = e => {
    setIsOpen(!isOpen)
  }
  const submitHandler = event => {
    event.preventDefault()
  }

  const onSelectChange = e => {
    setType(e.target.value)
  }
  const [areaVal, setAreaVal] = useState("")
  const [areasList, setAreasList] = useState([])
  const [areasArr, setAreasArr] = useState([])

  const handleMultiAreaFun = val => {
    let areas = []
    if (_.isEmpty(find(areasArr, val))) {
      areas = [...areasArr]
      areas.push(val)
      setAreasArr(areas)
    }
  }

  const removeArea = (area, clear = false) => {
    if (!clear) {
      let prevAreas = areasArr.filter(x => x.slug !== area)
      setAreasArr(prevAreas)
    } else {
      setAreasArr([])
    }
  }

  const submitSearchResiSale = e => {
    //var areaValue = areasArr.map(items => items.slug.toLowerCase()).join("-and-")
    var areaValue = areaVal
      .replace(/ /g, "-")
      .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
      .toLowerCase()
    if (areasArr?.length > 0) {
      areaValue = areasArr.map(items => items.slug.toLowerCase()).join("-and-")
    }

    var searchFilterResults = ""

    if (type === "1") {
      if (areaValue) {
        searchFilterResults = "/property/for-sale/in-" + areaValue + "/"
      } else {
        searchFilterResults = "/property/for-sale/in-ireland/"
      }
    } else if (type === "2") {
      if (areaValue) {
        searchFilterResults = "/new-homes-for-sale/in-" + areaValue + "/"
      } else {
        searchFilterResults = "/new-homes-for-sale/in-ireland/"
      }
    } else if (type === "3") {
      if (areaValue) {
        searchFilterResults =
          "/commercial-property/for-sale/in-" + areaValue + "/"
      } else {
        searchFilterResults = "/commercial-property/for-sale/in-ireland/"
      }
    } else if (type === "4") {
      if (areaValue) {
        searchFilterResults = "/auction-property/for-sale/in-" + areaValue + "/"
      } else {
        searchFilterResults = "/auction-property/for-sale/in-ireland/"
      }
    }

    navigate(searchFilterResults)
    window.scrollTo(0, 0)
  }

  const submitSearchResiRent = e => {
    //var areaValue = areasArr.map(items => items.slug.toLowerCase()).join("-and-")
    var areaValue = areaVal
      .replace(/ /g, "-")
      .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
      .toLowerCase()
    if (areasArr?.length > 0) {
      areaValue = areasArr.map(items => items.slug.toLowerCase()).join("-and-")
    }

    var searchFilterResults = ""

    if (type === "1") {
      if (areaValue) {
        searchFilterResults = "/property/to-rent/in-" + areaValue + "/"
      } else {
        searchFilterResults = "/property/to-rent/in-ireland/"
      }
    } else if (type === "3") {
      if (areaValue) {
        searchFilterResults =
          "/commercial-property/to-rent/in-" + areaValue + "/"
      } else {
        searchFilterResults = "/commercial-property/to-rent/in-ireland/"
      }
    }

    navigate(searchFilterResults)
    window.scrollTo(0, 0)
  }

  let processedImages = JSON.stringify({})
  if (props?.imagetransforms?.Banner_Image_Transforms) {
    processedImages = props.imagetransforms.Banner_Image_Transforms
  }
const handleScroll=()=>{
  const nodeEl=document.getElementById("licensee-form-component");
  nodeEl.scrollIntoView({behavior:"smooth"})
 
}
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className={`static-banner ${props.customCss}`}
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          {/* <picture>
						<source media="(min-width: 992px)" srcSet={props.Banner_Image?.url} />
						<source media="(min-width: 768px)" srcSet={props.Banner_Image?.url} />
						<img src={props.Banner_Image?.url} alt={props.Banner_Image?.alternativeText} className="banner-img" />
					</picture> */}
          <ImageTransform
            imagesources={props?.Banner_Image?.url}
            renderer="srcSet"
            imagename="page.Banner_Image.half"
            attr={{
              alt: props.Banner_Image?.alternativeText
                ? props.Banner_Image?.alternativeText
                : props?.Banner_Title + " - DNG Estate Agents",
              className: "banner-img",
            }}
            imagetransformresult={processedImages}
            id={props.id}
          ></ImageTransform>
          <Container className="banner-wrap">
            <Row>
              <Col sm="12">
                <div className={`banner-content ${props.Pagename}`}>
                  {props?.Banner_Title && (
                    <motion.h1 variants={contentItem}>
                      {props?.Banner_Title}
                    </motion.h1>
                  )}
                  {props?.Banner_Content && (
                    <motion.div variants={contentItem}>
                      <ReactMarkdown
                        source={props?.Banner_Content}
                        allowDangerousHtml
                      />
                    </motion.div>
                  )}
                  {/* {JSON.stringify(props.Cta_Links)} */}
                  {props.Cta_Links?.CTA_Btn_Label1 ||
                  props.Cta_Links?.CTA_Btn_Label2 ||
                  props.Cta_Links?.HashLink1 ||
                  props.Cta_Links?.HashLink2 ||
                  props.Cta_Links?.CTA_Btn_Url1?.Alias ||
                  props.Cta_Links?.CTA_Btn_Url2?.Alias ? (
                    <motion.div
                      className="bottom-fixed-btn d-lg-flex"
                      variants={contentItem}
                    >
                      {props.Cta_Links?.CTA_Btn_Label1 &&
                        props.Cta_Links?.HashLink1 && (
                          <a onClick={props.executeScroll} className="btn">
                            {props.Cta_Links?.CTA_Btn_Label1}
                          </a>
                        )}
                      {props.Cta_Links?.CTA_Btn_Label2 ===
                        "send us your cv" && (
                        <a
                          href="javascript:;"
                          onClick={() => handleShow()}
                          className="btn outline-btn"
                        >
                          Send us your cv
                        </a>
                      )}
                      
                      <Modal
                        show={show}
                        centered={true}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
                      >
                        <Modal.Header closeButton className="contact-close-btn">
                          <Modal.Title className="w-100">
                            Applying Job
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="form">
                          <CareerForm />
                        </Modal.Body>
                      </Modal>

                      {
                        props.Alias==="become-a-franchisee"&& props.Cta_Links.CTA_Btn_Label1==="Contact Us"&&
                        <a onClick={()=>handleScroll()} className="btn">
                            {props.Cta_Links?.CTA_Btn_Label1}
                          </a>
                      }
                      {props.Cta_Links?.CTA_Btn_Label1 &&
                        props.Cta_Links?.CTA_Btn_Url1?.Alias && (
                          <GetURL
                            class="btn"
                            label={props.Cta_Links?.CTA_Btn_Label1}
                            alias={props.Cta_Links?.CTA_Btn_Url1?.Alias}
                          />
                        )}
                      {props.Cta_Links?.CTA_Btn_Label2 &&
                        props.Cta_Links?.CTA_Btn_Url2?.Alias && (
                          <GetURL
                            class="btn outline-btn"
                            label={props.Cta_Links?.CTA_Btn_Label2}
                            alias={props.Cta_Links?.CTA_Btn_Url2?.Alias}
                          />
                        )}

                        {props.Cta_Links?.HashLink2&& props.Cta_Links.CTA_Btn_Label2&&
                        <Link to={props.Cta_Links?.HashLink2} className="btn outline-btn">{props.Cta_Links.CTA_Btn_Label2}</Link>                   
                        }
                    </motion.div>
                  ) : null}
                  {props?.Show_Form && (
                    <motion.div className="banner-tab" variants={contentItem}>
                      <div className="form-wrap">
                        <form onSubmit={submitHandler}>
                          <div className="form-content">
                            <div className="input-wrap">
                              <select
                                onClick={e => toggling(e)}
                                className={isOpen ? "is-open" : ""}
                                onChange={e => onSelectChange(e)}
                                value={type}
                              >
                                <option value="1">Residential {}</option>
                                <option value="2">New Homes</option>
                                <option value="3">Commercial</option>
                                <option value="4">Auctions</option>
                              </select>
                              <div className="input-wrapper">
                                {/* <input type="text" className="form-control" placeholder="Start typing your property address" /> */}
                                <SearchMultipleArea
                                  areaVal={areaVal}
                                  placeholder={"Location or Address"}
                                  setAreaVal={setAreaVal}
                                  setAreasList={setAreasList}
                                  handleMultiAreaFun={handleMultiAreaFun}
                                  autosuggestInputProps={{
                                    className:
                                      "react-autosuggest__input refine-autosuggest-input",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="d-md-none">
                              <div className="predictive-arelist-suggestion property-results p-0">
                                <AreaListBox
                                  areas={areasArr}
                                  removeArea={removeArea}
                                />
                              </div>
                            </div>
                            <div className="btn-wrapper">
                              {/* {type} */}
                              <button
                                className="btn"
                                onClick={submitSearchResiSale}
                              >
                                For Sale
                              </button>
                              {type === "1" ? (
                                <button
                                  className="btn"
                                  onClick={submitSearchResiRent}
                                >
                                  To Rent
                                </button>
                              ) : type === "3" ? (
                                <button
                                  className="btn"
                                  onClick={submitSearchResiRent}
                                >
                                  To Rent
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </form>
                        <div className="d-none d-md-block">
                          <div className="predictive-arelist-suggestion property-results p-0">
                            <AreaListBox
                              areas={areasArr}
                              removeArea={removeArea}
                            />
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )}
                  {props?.Show_Review && props.Pagename === "Reviews" ? (
                    <motion.div
                      className="google-review"
                      variants={contentItem}
                    >
                      <div className="review-logo">
                        <img src={GoogleReviews} alt="logo" width="62" />
                      </div>
                      <p className="d-md-none">
                        Rated {parseFloat(rating_avg).toFixed(1)} out of{" "}
                        {testimonial_count}
                      </p>
                      <p className="d-none d-md-block">
                        Rated {parseFloat(rating_avg).toFixed(1)} from{" "}
                        {testimonial_count} customer reviews
                      </p>
                    </motion.div>
                  ) : props?.Show_Review ? (
                    <Link to={Reviews_Page_Url.alias}>
                      <motion.div
                        className="google-review"
                        variants={contentItem}
                      >
                        <div className="review-logo">
                          <img src={GoogleReviews} alt="logo" width="62" />
                        </div>
                        <p className="d-md-none">
                          Rated {parseFloat(rating_avg).toFixed(1)} out of{" "}
                          {testimonial_count}
                        </p>
                        <p className="d-none d-md-block">
                          Rated {parseFloat(rating_avg).toFixed(1)} from{" "}
                          {testimonial_count} customer reviews
                        </p>
                      </motion.div>
                    </Link>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Container>
        </motion.div>
      )}
    </InView>
  )
}

export default StaticBanner
