import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import "./ServiceAdvisor.scss"
import { inViewOptions, contentItemStagger, contentItem, containerVariants, delayItemsFromBottom } from '@Components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import ReactMarkdown from "react-markdown"
import GetURL from '@Components/common/site/get-url';
// markup
const MotionCol = motion(Col)
const MotionRow = motion(Row)
const ServiceAdvisor = (props) => {
  return (<React.Fragment>
    <div className="service-advisor">
      <Container>
        <Row>
          <Col md="12" lg="10">

            <InView {...inViewOptions}>
              {({ ref, inView }) => (
                <motion.div
                  className="advantage-title"
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={contentItemStagger}
                >

                  {props?.Title && <motion.div variants={contentItem}><ReactMarkdown source={props?.Title} allowDangerousHtml /></motion.div>}
                </motion.div>
              )}
            </InView>
          </Col>
        </Row>
        <InView {...inViewOptions}>
          {({ ref, inView }) => (
            <MotionRow
              className="advantage-title"
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={contentItemStagger}
            >
              {props.Tail_Block?.map((item, index) => (
                <MotionCol key={index}
                  custom={index}
                  variants={contentItem} md="6" xl="3" className="d-flex mb-space">
                  <div className="our-block">
                    <div className="icon-box">
                      <i style={{ backgroundImage: `url(${item.Icon.url})`, backgroundSize: 'contain', backgroundColor: '#fff' }}></i>
                    </div>
                    <div className="content-text">
                      <h3><GetURL label={item.Parent_label} alias={item.Parent_Url?.Alias} /></h3>
                      <ul>
                        {item.Sub_Links.map((list, index) => (
                          <li>
                            <GetURL label={list.Label} alias={list.Url?.Alias} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </MotionCol>
              ))}
            </MotionRow>
          )}
        </InView>

      </Container>

    </div>
  </React.Fragment>
  )
}
export default ServiceAdvisor