import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import "./OurAdvantage.scss"
// markup
import { useStaticQuery, graphql } from "gatsby";
import { inViewOptions, contentItemStagger, contentItem } from '@Components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import ReactMarkdown from "react-markdown"
import GetURL from '@Components/common/site/get-url';
const MotionCol = motion(Col)
const MotionRow = motion(Row)
const OurAdvantage = (props ) => {
  const data = useStaticQuery(graphql`
	query{
		glstrapi {
			statistic(publicationState: LIVE) {
				Statistics_Item {
				  Content
				  Number
				  Symbol
				  Icon {
					alternativeText
					url
				  }
				}
				Title
			  }
		  }
		}
			  `);

  return ( 
        <React.Fragment>
          	<InView {...inViewOptions}>
			{({ ref, inView }) => (
				<motion.div
					className="our-advantage statics"
					ref={ref}
					initial="hidden"
					animate={inView ? "visible" : "hidden"}
					variants={contentItemStagger}
				>
            <Container>
              <Row>
                <Col md="12">
                <div className="advantage-title">
                  {props.Title ?<motion.div variants={contentItem}><ReactMarkdown source={props?.Title} allowDangerousHtml /></motion.div>
                 : data.glstrapi?.statistic?.Title ? <motion.div variants={contentItem}><ReactMarkdown source={data.glstrapi?.statistic?.Title} allowDangerousHtml /></motion.div> : null}
                </div>
                </Col>
               </Row>
                <Row>
                {data.glstrapi?.statistic?.Statistics_Item?.map((item, i) => (
                  <MotionCol key={i}
                  custom={i}
                  variants={contentItem} md="6" xl="3" className="d-flex mb-space">
                  <div className="our-block">
                        <div className="icon-box">
                          <i className="icon-users" style={{ backgroundImage: `url(${item.Icon.url})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}></i>
                        </div>
                        <div className="content-text">
                            <h3>{item.Number}{item.Symbol}</h3>
                            <ReactMarkdown source={item?.Content} allowDangerousHtml />
                          {/* <Link href="#" className="link-text">+ find out more</Link> */}
                          {/* <GetURL class="link-text"
                                      label={data.glstrapi?.statistic?.Parent_Label} alias={subitem.Parent_Url?.Alias}
                                    /> */}
                        </div>
                    </div>
                    </MotionCol>
                ))}
                {/* <Col md="6" xl="3" className="d-flex mb-space">
                <ScrollAnimation animateIn="fadeInUp">
                <div className="our-block">
                        <div className="icon-box">
                        <i className="icon-trendup"></i>
                        </div>
                        <div className="content-text">
                            <h3>9/10</h3>
                            <p>people whose house DNG sell either match or break the guide/asking price.</p>
                            <Link href="#" className="link-text">+ find out more</Link>
                        </div>
                    </div>
                    </ScrollAnimation>
                </Col>
                
                <Col md="6" xl="3" className="d-flex mb-space">
                <ScrollAnimation animateIn="fadeInUp">
                <div className="our-block">
                        <div className="icon-box">
                        <i className="icon-cursor"></i>
                        </div>
                        <div className="content-text">
                            <h3>6,900</h3>
                            <p>people clicked pages of the 180 properties that were put up last month.</p>
                            <Link href="#" className="link-text">+ find out more</Link>
                        </div>
                    </div>
                    </ScrollAnimation>
                </Col>

                <Col md="6" xl="3" className="d-flex mb-space">
                <ScrollAnimation animateIn="fadeInUp">
                  <div className="our-block">
                     <div className="icon-box">
                        <i className="icon-house-line"></i>
                        </div>
                        <div className="content-text">
                            <h3>8,200+</h3>
                            <p>active registrants who are currently looking for a property to buy or to rent.</p>
                            <Link href="#" className="link-text">+ find out more</Link>
                        </div>
                    </div>
                    </ScrollAnimation>
                </Col> */}
             
              </Row>
            </Container>
            </motion.div>
			)}
		</InView>
        </React.Fragment>
    )
}
export default OurAdvantage