import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll'; 
import ServicesImg from "../../../images/services-img1.jpg"
import ServicesImg768 from "../../../images/services-img1.jpg"
import ServicesImg1400 from "../../../images/services-img1.jpg"
import ServicesImg1800 from "../../../images/services-img1.jpg"
import PlayVideo from '../../PlayVideo/PlayVideo';
import "./MarketingServices.scss"
import ReactMarkdown from "react-markdown"
import GoogleReviews from "../../../images/google-reviews.png"
import { inViewOptions, contentItemStagger, contentItem } from '@Components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import GetURL from '@Components/common/site/get-url';
import axios from "axios"
import wordsToNumbers from 'words-to-numbers';
import { Reviews_Page_Url } from "@Components/common/site/constants";
import {token} from "@Components/forms/api/Api"

// Header component

const MarketingServices = (props) => {
    const [isOpen, setIsOpen] = useState(false);
	const toggling = () => setIsOpen(!isOpen);
	const [testimonials, setTestimonials] = useState([])
	const [isClient, setClient] = useState(false);
	const getitems = async url => {
	  try {
		const { data } = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${token}`,
				// "Content-Type": "multipart/form-data"
			}
		})// could be from env files
		setTestimonials(data)
	  } catch (error) {
		// cache it if fail/error;
		console.error(error)
	  }
	}
	useEffect(() => {
  
	  let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
	  getitems(url);
	  setClient(true);
	}, [])
	var testimonial_count = testimonials ? testimonials.length : 0;
	var rating_count = 0;
	testimonials && testimonials.length > 0 && testimonials.map((item, ind) => {
	  rating_count += parseInt(wordsToNumbers(item.starRating));
	})
	var rating_avg = rating_count / testimonial_count;
    const [isPlay, setPlay] = useState(false);
    return (
        <React.Fragment>
            		<InView {...inViewOptions}>
			{({ ref, inView }) => (
				<motion.section
					className="marketing-services"
					ref={ref}
					initial="hidden"
					animate={inView ? "visible" : "hidden"}
					variants={contentItemStagger}
				>
                <Container>
                    <Row className="flex-md-column-reverse flex-lg-row">
                        
                        <Col lg="7">
                            <div className="services-content">
                                
                            {props?.Banner_Title && <motion.h2 variants={contentItem}>{props?.Banner_Title}</motion.h2>}
									{props?.Banner_Content && <motion.div variants={contentItem}><ReactMarkdown source={props?.Banner_Content} allowDangerousHtml /></motion.div>}
                                    <motion.div className="bottom-fixed-btn d-lg-flex" variants={contentItem}>
										{props.Cta_Links?.CTA_Btn_Label1 && props.Cta_Links?.CTA_Btn_Url1?.Alias &&
											<GetURL class="btn" label={props.Cta_Links?.CTA_Btn_Label1} alias={props.Cta_Links?.CTA_Btn_Url1?.Alias} />

										}
										{props.Cta_Links?.CTA_Btn_Label2 && props.Cta_Links?.CTA_Btn_Url2?.Alias &&
											<GetURL class="btn outline-btn" label={props.Cta_Links?.CTA_Btn_Label2} alias={props.Cta_Links?.CTA_Btn_Url2?.Alias} />
										}
									</motion.div>
                                {props?.Show_Review &&
									<Link to={Reviews_Page_Url.alias}>
										<motion.div className='google-review' variants={contentItem}>
											<div className="review-logo">
												<img src={GoogleReviews} alt="reviews" width="62" />
											</div>
											<p className="d-md-none">Rated {parseFloat(rating_avg).toFixed(1)} out of {testimonial_count}</p>
											<p className="d-none d-md-block">Rated {parseFloat(rating_avg).toFixed(1)} from {testimonial_count} customer reviews</p>
										</motion.div>
										</Link>
									}                            </div>
                        </Col>
                        <Col lg="5">
						<motion.div variants={contentItem}>
                            <picture className="service-img img-zoom">
                                    <source media="(min-width:1800px)" srcSet={props.Banner_Image?.url} />
                                    <source media="(min-width:992px)" srcSet={props.Banner_Image?.url} />
                                    <source media="(min-width:768px)" srcSet={props.Banner_Image?.url} />
                                    <img src={props.Banner_Image?.url} alt={props.Banner_Image?.alternativeText+" - DNG Estate Agents"} />
                                    {props.Video_url &&
                                        <button type="button" className="play-btn" onClick={()=> {setPlay(true)}}>
                                            <i className="icon-play"></i>
                                        </button>
                                    }
                            </picture>
							</motion.div>
                            
                          </Col>
                    </Row>
                </Container>
                <PlayVideo isOpen={isPlay} stopPlay={setPlay} videoId={props.Video_url}/>
				</motion.section>
			)}
		</InView>
        </React.Fragment>
    )
}
export default MarketingServices
